import { createStore } from 'vuex'

export default createStore({
  state: {
    loader:0,
    exhibitor:{},
    exhtype:'local_government',
    exh_token_key:'',
    is_view_only:0,
    come_form_geenrated_link:0,
    link_type:'',
    flight_token:'',
    trip_token:'',
    trip_token:'',
    option_number:0,
    trips_option:[],
    come_from_trip:0,
    render_partner:true,
    config_list:{},
    // baseURL:'https://devbiban.com/api/',
    // baseURLBadge:"https://devbiban.com/visitor-badge?t=",
    // baseURLuploadImage:'https://devbiban.com/',
    // baseURLImage:'https://devbiban.com/images/',
    baseURL:'https://biban.benchmark.events/api/',
    baseURLBadge:"https://biban.benchmark.events/visitor-badge?t=",
    baseURLuploadImage:'https://biban.benchmark.events/',
    baseURLImage:'https://biban.benchmark.events/images/',
    // saved_exh_data:{},
    showloader_registration : 0,
    guest:{},
    saved_guest_data:{},
    visa_guest_type:"guest",
    visa_guest_token:"",
    media_user:{},
    media_user_token:'',
    media_staff_user:{},
    media_staff_user_secuirty:{},
    media_staff_user_token:'',
    speaker_user:{},
    speaker_user_token:'',
    security_check_data:{},
    exh_guest_token_key:'',
    normal_guest_token_key:'',
    normal_guest:{},
    visitor:{},
    saved_normal_guest_data:{
      phonenumber:''
    },
    vip_user_token:'',
    vip_user:{},
    vip_staff_user:{},
    survey:{},
    token_is_valide:0,
    en_title_list:[
      {
        id:'mr',
        text:'Mr.'
      },
      {
        id:'ms',
        text:'Ms.'
      },
      {
        id:'mrs',
        text:'Mrs.'
      },
      {
        id:'doctor',
        text:'Doctor'
      },
      {
        id:'professor',
        text:'Professor'
      },
    ],
    ar_title_list:[
      {
        id:'mr',
        text:'السيد'
      },
      {
        id:'ms',
        text:'  الآنسة'
      },
      {
        id:'mrs',
        text:' السيدة'
      },
      {
        id:'doctor',
        text:' الدكتور'
      },
      {
        id:'professor',
        text:' البروفسير'
      },
    ],
    ar_passport_type:[
      {
        id:'Ordinary Passport',
        text:'جواز سفر عادي',
      },
      {
        id:'Official Passport',
        text:'جواز سفر رسمي',
      },
      {
        id:'Diplomatic Passport',
        text:'جواز سفر دبلوماسي',
      },
      {
        id:'Emergency Travel Document',
        text:'وثيقة سفر طارئة',
      },
    ],
    en_passport_type:[
      {
        id:'Ordinary Passport',
        text:'Ordinary Passport',
      },
      {
        id:'Official Passport',
        text:'Official Passport',
      },
      {
        id:'Diplomatic Passport',
        text:'Diplomatic Passport',
      },
      {
        id:'Emergency Travel Document',
        text:'Emergency Travel Document',
      },
    ],
    en_role_list:[
      {
        id:'C-Level (CEO,VP,GM,Partner)',
        text:'C-Level (CEO,VP,GM,Partner)',
      },
      {
        id:'Senior Mangement (Director, Senior Manager)',
        text:'Senior Mangement (Director, Senior Manager)',
      },
      {
        id:'Middle Management (Manager, Team Leader)',
        text:'Middle Management (Manager, Team Leader)',
      },
      {
        id:'Team Member',
        text:'Team Member',
      },
      {
        id:'Entrepreneur',
        text:'Entrepreneur',
      },
      {
        id:'Investor',
        text:'Investor',
      },
    ],
    ar_role_list:[
      {
        id:'C-Level (CEO,VP,GM,Partner)',
        text:'الإدارة التنفيذية (الرئيس التنفيذي، نائب الرئيس، المدير العام، الشريك)',
      },
      {
        id:'Senior Mangement (Director, Senior Manager)',
        text:'الإدارة العليا (مدير الإدارة، مدير أول)',
      },
      {
        id:'Middle Management (Manager, Team Leader)',
        text:'الإدارة الوسطى (مدير، قائد فريق)',
      },
      {
        id:'Team Member',
        text:'عضو فريق',
      },
      {
        id:'Entrepreneur',
        text:'رائد أعمال',
      },
      {
        id:'Investor',
        text:'مستثمر',
      },
    ],
    en_visitor_role:[
      {
        id:'Venture Capitalist',
        text:'Venture Capitalist',
      },
      {
        id:'Angel Investor',
        text:'Angel Investor',
      },
      {
        id:'Investor',
        text:'Investor',
      },
      {
        id:'Entrepreneur',
        text:'Entrepreneur',
      },
      {
        id:'Founder',
        text:'Founder',
      },
      {
        id:'Employee',
        text:'Employee',
      },
      {
        id:'Academic',
        text:'Academic',
      },
      {
        id:'Executive',
        text:'Executive',
      },
    ],
    ar_visitor_role:[
      {
        id:'Venture Capitalist',
        text:'مستثمر رأس المال الجريء ',
      },
      {
        id:'Angel Investor',
        text:'مستثمر ملائكي',
      },
      {
        id:'Investor',
        text:'مستثمر',
      },
      {
        id:'Entrepreneur',
        text:'رائد أعمال',
      },
      {
        id:'Founder',
        text:'مؤسس',
      },
      {
        id:'Employee',
        text:'موظف',
      },
      {
        id:'Academic',
        text:'أكاديمي',
      },
      {
        id:'Executive',
        text:'تنفيذي',
      },
    ],
    lunching_door:{
      ar:{
        workshops:[
          {
            title:"من الفكرة إلى السوق",
            desc:"تركز على شرح منهجية الابتكار، والإستراتيجيات التي يتبعها رائد الأعمال خلال عملية الابتكار لتحويل فكرته إلى نموذج أولي واختبارها",
            time:"4:30 - 8:00",
            duration:"3",
            hour:"ساعات",
            topics:[
              'نظرة عامة لرحلة باب الانطلاقة',
              'التفكير التصميمي وتوليد الأفكار',
              'نموذج الأعمال ومنتج الحد الأدنى القابل للتطبيق MVP',
            ]
          },
          {
            title:"دراسة الجدوى",
            desc:"تركز على توضيح أهمية إعداد دراسة جدوى للأعمال والمشاريع وتوضيح السمات والمكونات الأساسية لها، لمعرفة فرص النجاح ورسم خارطة الطريق",
            time:"5:30 - 9:00",
            duration:"3",
            hour:"ساعات",
            topics:[
              "أهمية واستخدامات دراسة الجدوى",
              "مكونات دراسة الجدوى وأهم مصادر دراسات السوق",
              "تطبيقات على نموذج دراسة الجدوى ",
            ]
          },
          {
            title:"خطوات تأسيس العمل التجاري",
            desc:"تركز على توضيح إجراءات وخطوات تأسيس العمل التجاري كما تساعد على تعزيز قدرات المشاركين في تأسيس المنشآت",
            time:"4:30 - 8:00",
            duration:"3",
            hour:"ساعات",
            topics:[
              "مرحلة ما قبل التأسيس",
              "مرحلة استخراج تراخيص النشاط الاقتصادي",
              "مرحلة تجهيز الموقع",
              "مرحلة إدارة واستقطاب القوى العاملة",
              "تحديد طرق تمويل المشروع",
              "مرحلة التشغيل",
              "أدلة انشطة التجزئة",
              "التعريف بملتقى بيبان ومنطقة الجهات الداعمة  ",
            ]
          }
        ]
      },
      en:{
        workshops:[
          {
            title:"From idea to market",
            desc:"An exploration of methodology and strategy: how entrepreneurs employ innovative processes to transform their idea into a prototype/proof of concept. After that, we put it to the test. ",
            time:"4:30 - 8:00",
            duration:"3",
            hour:"Hours",
            topics:[
              "An overview of the Launching Door Journey",
              "Design thinking and idea generation",
              "Business Model and Minimum Viable Product (MVP)",
            ]
          },
          {
            title:"Feasibility Study",
            desc:"Highlighting the importance of preparing a feasibility study for businesses and projects and clarifying basic features and components thereof, thus gaining insight into the chance of success and generating a road map to it.  ",
            time:"4:30 - 8:00",
            duration:"3",
            hour:"Hours",
            topics:[
              "The importance of (and uses for) feasibility studies",
              "Feasibility study components and market study sources",
              "Applications of the feasibility study model",
            ]
          },
          {
            title:"Steps to Establishing a Business",
            desc:"Shedding light on the procedures and steps for establishing a business and guiding aspiring business owners through the process. ",
            time:"4:30 - 8:00",
            duration:"3",
            hour:"Hours",
            topics:[
              "Pre-establishment stage",
              "Obtaining licenses for economic activities",
              "Site preparation stage",
              "Managing and attracting the workforce",
              "Determining financing methods and sources",
              "Operation phase",
              "Retail activity guide",
              "Biban forum and supporting authorities area",
            ]
          }
          // {
          //   title:"",
          //   desc:"",
          //   time:"",
          //   duration:"",
          //   topics:[]
          // }
        ]
      },

    },
    growth_door: {
    "ar": {
        "workshops": [
            {
                "title": "التخطيط الإستراتيجي",
                "desc": "تُركز على المعارف المطلوبة للإدارة والتخطيط الإستراتيجي في المنشآت الصغيرة والمتوسطة، وكيفية تحديد الأهداف الطويلة والمتوسطة والقصيرة الأجل، ويتعرف المشاركون على أساليب تحديد الخيارات الإستراتيجية المناسبة.",
                "time": "4:30 - 5:30",
                "duration": "1",
                "hour":"hour",
                "topics": [
                    "أهمية التخطيط الإستراتيحي",
                    "أدوات التخطيط للأعمال",
                    "خطوات إعداد الإستراتجية التشغيلية",
                ]
            },
            {
                "title": "إدارة سلاسل الإمداد",
                "desc": "تهدف إلى التعريف بمفاهيم إدارة سلاسل الإمداد، وعناصرها، وكيفية تفاعل هذه العناصر مع بعضها لبناء إستراتيجية تنافسية فاعلة تمكّن المنظمة من تخفيض تكاليفها وزيادة حصتها السوقية.",
                "time": "4:30 - 5:30",
                "duration": "1",
                "hour":"hour",
                "topics": [
                    "مفهوم ومبادئ إدارة سلاسل الإمداد ",
                    "أنواع إدارة سلاسل الإمداد حسب طبيعة الأعمال",
                    "العمليات الرئيسية في إدارة سلاسل الإمداد",
                ]
            },
            {
                "title": "نمو المنشآت",
                "desc": "مقدمة في إستراتيجيات النمو لأصحاب المنشآت الصغيرة والمتوسطة و التعرف على الأدوات والإستراتيجيات التي تساعد في الاستمرار والتوسع وفق فهم واضح لمراحل النمو المختلفة ومتطلباتها.",
                "time": "4:30 - 5:30",
                "duration": "1",
                "hour":"ساعة",
                "topics": [
                    " تقييم الوضع الحالي للمنشأة ",
                    "أدوات وإستراتيجيات تنمية الأعمال",
                    "التعرف على مراحل النمو للمنشآت الصغيرة والمتوسطة",
                ]
            },
            {
                "title": "التسويق لأعمال المنشآت",
                "desc": "تهدف إلى مساعدة أصحاب المنشآت للنهوض بأعمالهم من خلال توظيف الأدوات التسويقية المناسبة والتي تتلاءم مع الموارد وبما يلبي حاجات ورغبات السوق المستهدف. تغطي الورشة سلسلة مراحل بهدف تمكين المشاركين من تسويق المنتجات والخدمات بحسب أفضل الممارسات في مجال الأعمال",
                "time": "4: 30 - 5: 30",
                "duration": "1",
                "hour":"ساعة",
                "topics": [
                    "التعرف على مفهوم التسويق وأبعاده",
                    "التعرف على آليات تقسيم واستهداف السوق وإحلاله",
                    "المزيج التسويقي وكيفية إعداده",
                ]
            },
            {
                "title": "الإدارة المالية ",
                "desc": "تركز على تعزيز جودة الإدارة المالية والارتقاء بها وتعريف المشاركين بالقوائم المالية وأنواعها المختلفة، كما توضح كيفية احتساب النسب الربحية وتحسين التدفقات النقدية.",
                "time": "4:30 - 5:30",
                "duration": "1",
                "hour":"ساعة",
                "topics": [
                    "مقدمة في الإدارة المالية",
                    "قراءة وتحليل القوائم المالية ",
                    "إدارة التدفقات النقدية",
                    "تحديد الاحتياجات التمويلية",
                ]
            },
            {
                "title": "إدارة الموارد البشرية ",
                "desc": "مقدمة في إدارة الموارد البشرية والمفاهيم والمهام الأساسية المرتبطة بها و مبادئ تخطيط الموارد البشرية والتحليل والتوظيف والاختيار والتدريب والتطوير.",
                "time": "4:30 - 5:30",
                "duration": "1",
                "hour":"ساعة",
                "topics": [
                    "تحليل الوظائف واحتياجات القوى العاملة",
                    "استقطاب العاملين والتوظيف والتدريب",
                    "إدارة الرواتب، التعويضات، المكافآت",
                    "التعريف بأهم مصادر أنظمة العمل",
                ]
            },
        ]
    },
    "en": {
        "workshops": [
            {
                "title": "Strategic Planning",
                "desc": "This door focuses on the skills and knowledge concerning SME management and strategic planning: how to set long, medium, and short-term goals in particular. Here, participants learn all about appropriate strategy methods and mechanisms.",
                "time": "4:30 - 5:30",
                "duration": "1",
                "hour":"hour",
                "topics": [
                    "The importance of strategic planning",
                    "Business planning tools",
                    "Steps to prepare strategy and operation",
                ]
            },
            {
                "title": "Supply Chain Management ",
                "desc": " The door introduces the concepts of supply chain management, its elements, and how these elements interact with each other, thus resulting in an effective competitive strategy through which organizations can reduce their costs and increase their market share.",
                "time": "4:30 - 5:30",
                "duration": "1",
                "hour":"hour",
                "topics": [
                    "The concept and principles of supply chain management",
                    "Types of supply chain management, depending on the nature of the business",
                    "The supply chain management’s main operations",
                ]
            },
            {
                "title": "SME Growth ",
                "desc": "Introduction to SME growth strategies and the tools that promote longevity and expansion; a breakdown of the different growth stages and their requirements.",
                "time": "4:30 - 5:30",
                "duration": "1",
                "hour":"hour",
                "topics": [
                    "Establishment evaluation ",
                    "Business development tools and strategies",
                    "Identifying SME growth stages",
                ]
            },
            {
                "title": "SME Marketing",
                "desc": "Introducing SME owners to appropriate and compatible marketing tools that help them meet their needs and reach their target market. The workshop covers a series of stages that enable participants in marketing products and services according to best business practices.",
                "time": "4: 30 - 5: 30",
                "duration": "1",
                "hour":"hour",
                "topics": [
                    "Understand marketing and its many dimensions",
                    "Learn about the mechanisms of segmenting and targeting the market",
                    "Marketing mix and how to prepare it    ",
                ]
            },
            {
                "title": "Financial Management ",
                "desc": "This workshop focuses on enhancing and improving the quality of financial management and introducing participants to financial statements of various types. It also explains how to calculate profitability ratios and stimulate cash flow.",
                "time": "4:30 - 5:30",
                "duration": "1",
                "hour":"hour",
                "topics": [
                    "Introduction to financial management",
                    "Financial statements analysis",
                    "Cash flow management ",
                    "Financing needs analysis",
                ]
            },
            {
                "title": "Human Resources  ",
                "desc": "Introduction to human resource management and all that implies: a breakdown of the principles of human resource planning, analysis, recruitment, selection, training, and development.",
                "time": "4:30 - 5:30",
                "duration": "1",
                "hour":"hour",
                "topics": [
                    "Analysis of jobs and human power needs",
                    "Attracting workers, hiring them, and training them",
                    "Payroll management, compensation, and bonuses",
                    "Identifying human resource references",
                            ]
            },
        ]
    },
},
    days : ['d_1', 'd_2', 'd_3', 'd_4', 'd_5', 'd_6', 'd_7'],
    monthes : ['m_1', 'm_2', 'm_3', 'm_4', 'm_5', 'm_6', 'm_7', 'm_8', 'm_9', 'm_10', 'm_11', 'm_12'],
    visa:{},
    no_visa_countries:["194", "232", "119", "181", "168", "18"],
    ar_qualification:[
      {id:1,text:'ثانوي'},
      {id:2,text:'بكالوريس'},
      {id:3,text:'ماجستير'},
      {id:4,text:'دكتوراه'},
    ],
    en_qualification:[
      {id:1,text:'Secondary'},
      {id:2,text:'Bachelor\'s degree'},
      {id:3,text:'Master\'s'},
      {id:4,text:'Ph.D'},
    ],
    tokennn:"eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI2IiwianRpIjoiMGFkNjcyY2RiNzRiYjQwZTliNzdmNjZiYzg0OGZkYTEyMzI3ODNiNjlhZDEzOWVlMWY1MDgwYjJjZTlhMGI2MWU2NDZkM2VmODA5MTllNDQiLCJpYXQiOjE2NzQxMzg5NzAuNzk4NzMyOTk1OTg2OTM4NDc2NTYyNSwibmJmIjoxNjc0MTM4OTcwLjc5ODczODAwMjc3NzA5OTYwOTM3NSwiZXhwIjoxNzA1Njc0OTcwLjc0Nzk2Nzk1ODQ1MDMxNzM4MjgxMjUsInN1YiI6IiIsInNjb3BlcyI6W119.Kdk0_B9eNxMH36OGPX8QLujCrW-VG0SMIGT6PZ_5alkftlcfkhZydj-GaggOGZbc80uVhL7lR0Z54T13K_twuml7zMrLn5a0rzaIZC4uMINB5RrK4eYk1jpASYqehpuxnKBkkNv66UBw55WRrj9kiKuW5iCZC6QHTQuhVJncwIBFDZVVHthOfncJKflVfuigNtZSChnFHIcpNm6XTNRexfskKRPFA1cpYahxaRYszCwRosIOjRUo7kIzvUTASiXvyerQQDptZJ56T0Fd4zLHFC5tZ5TGm-Iac_kKBiSfqe9f59menAzlVLeKIMKEjYbE4k9PaSRrGCYw5gSSo7mfuxQyQHEUIwYppuLcWeC1Iy3FVrNUuFq7JWf5-lWl0kQcEnBIx6lu2lJIRIFQclKAhMSZ7ebIrjhOdd5TSnqtv_h5lPhw_1nmVH28rvr7D99g7WPRC1yBz5dl4BSWhcnqziVet42WsDYVNNDbS-8bEdc_EgKpj91VaM86CRUcksdwAm7wXDyzFvdAHRmwjmwinCsveFtWu27xwFwZg9GbDdSmCMwvi5vEUQJCK-abOIALrNfLEkFGq_j6bEPFuX6fR_xbFLx6Xrnt446-hsFp-boCx3Nuopi03YZj7YfO6d0Rk5n66say-cUMFy6q8YM8LTarRgXfKRTLHeZAwntcGaE"
  },

  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
